import React from "react";
import { Container } from "react-bootstrap";
import IconBreadcrumbs from "../custom-breadcrumb";
import { Box, Button, Divider } from "@mui/material";
import config from "../../config.json";
const DownloadsPage = () => {
  const navigate = (url) => {
    window.location.href = url;
  };

  return (
    <Box className={"pb-5"}>
      <IconBreadcrumbs name={"Downloads"} />
      <Container className={"text-center"}>
        <p className={"font-semibold text-lg"}>Game Client Download</p>
        <div className={"flex justify-center"}>
          <p className={"text-left max-w-96"}>
            You can download and install Classic Conquer using the following
            download link. Once downloaded, simply install and run the game from
            the new desktop shortcut.
          </p>
        </div>
        <div className="flex gap-x-2 justify-center">
          <Button
            variant="contained"
            color="primary"
            className="feature__btn mb-2"
            style={{ borderRadius: "5px" }}
            onClick={() => navigate("https://drive.google.com/file/d/1KGqzIkS18VPzZ1TeMEXtzMRpqkSdvJLK/view?usp=sharing")}
            size={"large"}
          >
            <div className={"flex justify-center items-center gap-x-2"}>
              <i className="fa fa-windows fa-lg"></i>
              Mirror 1
            </div>
          </Button>
          <Button
            variant="contained"
            color="error"
            className="feature__btn mb-2"
            style={{ borderRadius: "5px" }}
            onClick={() => navigate("https://mega.nz/file/fVhQDKRR#20ECkOzqvch33xWzgltXjWkNbB2FUAh826Aw9Maq-PY")}
            size={"large"}
          >
            <div className={"flex justify-center items-center gap-x-2"}>
              <i className="fa fa-windows fa-lg"></i>
              Mirror 2
            </div>
          </Button>
        </div>
        <Divider className="my-5"/>
        <div>
          <p className="font-semibold text-lg">Are you having troubles starting the client?</p>
          <p>Download the All-in-one package to fix the missing files in the client.</p>
          <Button
            variant="contained"
            color="warning"
            className="feature__btn mb-2"
            style={{ borderRadius: "5px" }}
            onClick={() => navigate("https://drive.usercontent.google.com/download?id=1pn4DFfo3ApzobkJ2RVTZpY_sOHzH9_YG&export=download&authuser=0")}
            size={"large"}
          >
            <div className={"flex justify-center items-center gap-x-2"}>
              <i className="fa fa-windows fa-lg"></i>
              All-in-one Download
            </div>
          </Button>
        </div>
      </Container>
    </Box>
  );
};
export default DownloadsPage;
